<template>
  <v-list-item @click="download()">
    <v-list-item-icon>
      <v-icon color="success">mdi-download</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Descargar clientes</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <!-- <v-btn
      @click="download()"
      :dark="!loading"
      :color="!loading ? 'black' : 'gray'"
      :loading="loading"
      :disabled="loading"
    >
      <v-icon left>mdi-download</v-icon>
      Descargar Clientes
    </v-btn> -->
</template>
<script>
import XLSX from "xlsx";
export default {
  name: "clientesExcel",
  props: ["total"],
  data: () => ({
    loading: false,
  }),
  methods: {
    download() {
      let clientes = [];
      this.loading = true;
      const body = {
        route: `/clientes?page=1`,
        params: {
          perPage: this.total,
          search: null,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            clientes = response.data.data.data;
            const name = "Listado-clientes";
            const data = clientes.map((cliente) => {
              let genero = "Femenino";
              if (cliente.genero == "m") genero = "Masculino";
              else if (cliente.genero == "o") genero = "Otro";
              return {
                Nombre: cliente.nombre,
                "ID/CC": cliente.cedula,
                Correo: cliente.email,
                Telefono: cliente.telefono,
                Cumple: cliente.fecha,
                Puntos: cliente.puntos,
                Genero: genero,
                Direccion: cliente.direccion
              };
            });
            let excel = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            const filename = name;
            XLSX.utils.book_append_sheet(workbook, excel, filename);
            XLSX.writeFile(workbook, `${filename}.xlsx`);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
