<template>
  <div class="csv">
    <!-- <v-list-item @click="dialog = true">
      <v-list-item-icon>
        <v-icon color="primary">mdi-cloud-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Importar clientes</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <!-- <v-btn dark color="black" @click="dialog = true">
      <v-icon left>mdi-cloud-upload</v-icon>
      Importar Excel
    </v-btn> -->
    <v-dialog v-model="dialog" persistent scrollable max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline"> Importar listado de clientes </span>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" md="12" sm="12">
              <h3>
                <strong>Importante: </strong>
              </h3>
              <p>
                Podrás cargar un CSV (delimitado por comas). Los textos no deben
                contener comas (,) o punto y coma (;).
                <br />Te facilitamos una platilla con los campos necesarios para
                una correcta importación.<v-btn
                  small
                  text
                  color="primary"
                  @click="download()"
                >
                  <v-icon left>mdi-download</v-icon>
                  Descargar plantilla
                </v-btn>
                <br />
                <strong
                  >Solo se permite un maximo de 600 clientes por CSV.</strong
                >

                <br />Acontinuación veras una lista con los campos y sus
                caracteristicas requeridas
                <v-btn text small color="primary" @click="view = !view"
                  >Ver caracteristicas</v-btn
                >
              </p>
            </v-col>
            <v-col cols="12" md="12" sm="12" v-if="view">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Nombre</th>
                      <th>ID</th>
                      <th>Genero</th>
                      <th>Telefono</th>
                      <th>Dirección</th>
                      <th>Correo</th>
                      <th>Cumple</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <strong>Campo Requerido</strong>
                      </th>
                      <td>
                        <strong>Si</strong>
                      </td>
                      <td>
                        <strong>Si</strong>
                      </td>
                      <td>
                        <strong>No</strong>
                      </td>
                      <td>
                        <strong>No</strong>
                      </td>
                      <td>
                        <strong>No</strong>
                      </td>
                      <td>
                        <strong>Si</strong>
                      </td>
                      <td>
                        <strong>Si</strong>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <strong>Descripción</strong>
                      </th>
                      <td>
                        <small>
                          Nombre del cliente
                        </small>
                      </td>
                      <td>
                        <small>Cedula / ID del cliente</small>
                      </td>
                      <td>
                        <small
                          >Genero del cliente, <strong>M</strong> para
                          masculino, <strong>F</strong> para femenino. De ser
                          otro caso deja el campo vacio.
                        </small>
                      </td>
                      <td>
                        <small>Telefono del cliente</small>
                      </td>
                      <td>
                        <small>Dirección del cliente</small>
                      </td>
                      <td>
                        <small>Correo del cliente</small>
                      </td>
                      <td>
                        <small
                          >Fecha de cumpleaños del cliente, ingresa la fecha en
                          formato (Año, Mes, Dia)-(YYYY-MM-DD)</small
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-alert :type="type_alert" v-model="alert" dismissible>
                {{ text_alert }}
              </v-alert>
              <vue-csv-import
                v-model="csv"
                :headers="true"
                :map-fields="fields"
                :autoMatchFields="true"
                tableSelectClass="select-control"
                tableClass="table mt-2"
                class="mt-2"
              >
                <template slot="error">
                  Tipo de archivo invalido
                </template>
                <template slot="next" slot-scope="{ load }">
                  <v-btn @click.prevent="load" color="primary" class="mt-2">
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Cargar
                  </v-btn>
                </template>
              </vue-csv-import>
            </v-col>
            <v-col cols="12" md="12" sm="12" v-if="csv != ''">
              <h3 class="my-2 text-center">Listado elementos en CSV</h3>
              <v-simple-table dense class="mt-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>ID</th>
                      <th>Genero</th>
                      <th>Telefono</th>
                      <th>Dirección</th>
                      <th>Correo</th>
                      <th>Cumple</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in lista" :key="index">
                      <td>
                        {{ item.nombre }}
                      </td>
                      <td>
                        {{ item.cedula }}
                      </td>
                      <td>
                        <span v-if="item.genero == 'm'">Masculino</span>
                        <span v-if="item.genero == 'f'">Femenino</span>
                      </td>
                      <td>
                        {{ item.telefono }}
                      </td>
                      <td>
                        {{ item.direccion }}
                      </td>
                      <td>
                        {{ item.email }}
                      </td>
                      <td>
                        {{ moment(item.fecha).format("ll") }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="cancel()" text>
            Cancelar
          </v-btn>
          <v-btn
            color="black"
            dark
            @click="register()"
            :disabled="loading"
            :loading="loading"
          >
            Importar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
import { VueCsvImport } from "vue-csv-import";
export default {
  name: "csv",
  components: { VueCsvImport },
  props: ["dialog"],
  data: () => ({
    // dialog: false,
    loading: false,
    view: false,
    csv: [],
    type_alert: "success",
    alert: false,
    text_alert: "",
    fields: {
      nombre: "nombre",
      id: "id",
      genero: "genero",
      telefono: "telefono",
      direccion: "direccion",
      correo: "correo",
      cumple: "cumple",
    },
  }),
  methods: {
    register() {
      if (this.csv != "") {
        if (this.csv.length > 1000) {
          console.log("Es mayor");
        } else {
          this.loading = true;
          const body = {
            route: "/register_clientes_csv",
            data: {
              data: JSON.stringify(this.lista),
            },
          };
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                this.type_alert = "success";
                this.text_alert = response.data.data;
                this.alert = true;
                this.loading = false;
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            })
            .catch((error) => {
              this.type_alert = "error";
              this.text_alert = error.response.data.data;
              this.alert = true;
              this.loading = false;
            });
        }
      }
    },
    download() {
      const name = "plantilla-clientes";
      const data = [
        {
          nombre: "",
          id: "",
          genero: "",
          telefono: "",
          direccion: "",
          correo: "",
          cumple: "",
        },
      ];

      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    cancel() {
      this.$emit("setDialogCsv", false);
      this.csv = [];
    },
    moment(data) {
      return moment(data);
    },
  },
  computed: {
    lista() {
      this.csv.forEach((item, index) => {
        item.cumple =
          item.cumple == ""
            ? moment().format("YYYY-MM-DD")
            : moment(item.cumple).format("YYYY-MM-DD");
        item.genero = item.genero == "" ? "f" : item.genero.toLowerCase();
        item.telefono = item.telefono == "" ? "0000000" : item.telefono;
        item.direccion = item.direccion == "" ? "No registra" : item.direccion;
        if (item.nombre == "" || item.id == "" || item.correo == "") {
          this.csv.splice(index, 1);
        }
      });
      return this.csv.map((item) => {
        return {
          nombre: item.nombre,
          cedula: item.id,
          genero: item.genero,
          telefono: item.telefono,
          direccion: item.direccion,
          email: item.correo,
          fecha: item.cumple,
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    },
  },
};
</script>
