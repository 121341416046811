<template>
  <div class="clientes mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title> Clientes: {{ paginationServe.total }}</v-card-title>
          <v-card-subtitle>
            Crea, modifica y lleva un control de tus clientes.
          </v-card-subtitle>
          <v-card-text>
            <csv v-on:setDialogCsv="setDialogCsv($event)" :dialog="dialog_csv" />
            <!-- <clientesExcel :dialog="dialog_download" v-on:setDialogDownload="setDialogDownload($event)" :total="paginationServe.total" /> -->
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table dense :loading="loading_t" :headers="headers" :items="clientes" hide-default-footer
                  :items-per-page="perPage" :page.sync="page">
                  <template v-slot:top>
                    <v-row no-gutters>
                      <v-col cols="10" md="10" sm="9">
                        <v-row no-gutters>
                          <v-col cols="11" md="7" sm="7">
                            <v-text-field outlined rounded dense label="Buscar" v-model="search"
                              @keydown.enter.prevent="loadClientes"></v-text-field>
                          </v-col>
                          <v-col cols="1" md="1" sm="1">
                            <v-btn class="ml-2" small fab elevation="0" color="primary" @click="loadClientes">
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="4" sm="4">
                            <div style="display: flex;">
                              <v-subheader>
                                Clientes X pag
                              </v-subheader>
                              <div style="width:120px">
                                <v-select :items="itemsPerPage" v-model="perPage" outlined dense
                                  @change="setItemsPerPage"></v-select>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4" md="2" sm="3">
                        <v-btn dark color="black" s @click="dialog = true">
                          Crear
                        </v-btn>
                        <v-menu bottom right offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-2" small fab elevation="0" color="primary" v-on="on" v-bind="attrs">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="dialog_csv = true">
                              <v-list-item-icon>
                                <v-icon color="primary">mdi-cloud-upload</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Importar clientes</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <clientesExcel :total="paginationServe.total" />
                          </v-list>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4" sm="6">
                        <v-pagination dense v-model="page" :length="lengthPagination" :total-visible="6"
                          @input="changePage"></v-pagination>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.fecha="{ item }">
                    <span>
                      {{ moment(item.fecha).format("ll") }}
                    </span>
                  </template>
                  <template v-slot:item.editar="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon small @click="update(item)" v-on="on" v-bind="attrs">
                          <v-icon> mdi-pen </v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.eliminar="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-menu bottom right offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" icon small class="ml-2" v-on="on" v-bind="attrs">
                              <v-icon> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>Eliminar</v-card-title>
                            <v-card-text>
                              <div style="font-size: 1.3em">
                                ¿Estas seguro de eliminar a {{ item.nombre }}?
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary">Cancelar</v-btn>
                              <v-btn @click="deleteCliente(item)" dark color="black">Eliminar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" persistent scrollable max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="headline" v-if="!edit">Crear cliente</span>
                      <span class="headline" v-else>Actualizar cliente</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <formComponent :list="form" ref="cliente" />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="cancel()" color="primary">
                        Cancelar
                      </v-btn>
                      <v-btn :dark="!loading" :color="!loading ? 'black' : 'gray'" :loading="loading"
                        :disabled="loading" @click="register()" v-if="!edit">
                        Crear
                      </v-btn>
                      <v-btn v-if="edit" :dark="!loading" :color="!loading ? 'black' : 'gray'" :loading="loading"
                        :disabled="loading" @click="update()">
                        Actualizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import csv from "../../components/Csv.vue";
import clientesExcel from "../../components/ClientesExcel.vue";
import formComponent from "../../components/Form.vue";
export default {
  name: "clientes",
  components: {
    csv,
    formComponent,
    clientesExcel,
  },
  data: () => ({
    paginationServe: {
      total: 0,
      perPage: 25,
    },
    perPage: 25,
    page: 1,
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
      // {
      //   text: "Todos",
      //   value: -1,
      // },
    ],
    loading_t: false,
    dialog: false,
    dialog_csv: false,
    loading: false,
    search: "",
    clientes: [],
    id: null,
    headers: [
      {
        text: "ID",
        value: "cedula",
        align: "start",
      },
      {
        text: "Cliente",
        value: "nombre",
      },
      {
        text: "Puntos",
        value: "puntos",
      },
      {
        text: "Correo",
        value: "email",
        sortable: false,
      },
      {
        text: "Teléfono",
        value: "telefono",
        sortable: false,
      },
      {
        text: "Cumpleaños",
        value: "fecha",
        sortable: false,
      },
      {
        text: "",
        value: "editar",
        sortable: false,
      },
      {
        text: "",
        value: "eliminar",
        sortable: false,
      },
    ],
    edit: false,
    form: {
      ref: "clientes",
      list: [
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "ID / CC",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "cedula",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Cliente",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "nombre",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Correo",
          },
          validation: {
            valid: true,
            rules: "required|email",
          },
          id: "email",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Dirección",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "direccion",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Telefono",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "telefono",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "picker",
          menu: {
            model: false,
            label: "Cumpleaños",
          },
          validation: {
            valid: false,
          },
          id: "fecha",
          model: moment().format("YYYY-MM-DD"),
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "select",
          select: {
            label: "Genero",
            items: [
              { text: "Masculino", value: "m" },
              { text: "Femenino", value: "f" },
              { text: "Otros", value: "o" },
            ],
            hint: "Selecciona un genero",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "genero",
          model: "f",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Puntos",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "puntos",
          model: "",
          show: false,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: "switch",
          validation: {
            valid: false,
          },
          id: "estado",
          model: false,
          name: "Desactivar / Activar",
          show: false,
        },
      ],
    },
  }),
  methods: {
    changePage(data) {
      this.clientes = [];
      this.page = data;
      this.loadClientes();
    },
    setItemsPerPage(val) {
      if (val === -1) this.paginationServe.perPage = this.paginationServe.total;
      else this.paginationServe.perPage = val;

      this.loadClientes();
    },
    loadClientes() {
      this.clientes = [];
      this.loading_t = true;
      const body = {
        route: `/clientes?page=${this.page}`,
        params: {
          perPage: this.paginationServe.perPage,
          search: this.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.paginationServe.total = response.data.total;
            this.clientes = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    register() {
      this.$refs.cliente.validate().then((res) => {
        if (res) {
          this.loading = true;
          let body = {
            route: "/register_cliente",
            data: {
              data: this.$store.getters.validation_form(this.form.list),
            },
          };
          body.data.data.telefono =
            body.data.data.telefono == "" ? "0000000" : body.data.data.telefono;
          body.data.data.direccion =
            body.data.data.direccion == ""
              ? "No registra"
              : body.data.data.direccion;
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.loadClientes();
                this.cancel();
              }
            })
            .catch((error) => {
              this.loading = false;
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            });
        }
      });
    },
    update(...n) {
      if (n.length > 0) {
        this.edit = true;
        this.id = n[0].id;
        this.form.list.forEach((item) => {
          if (!item.show) {
            item.show = true;
          }
          if (item.id != undefined) {
            item.model = n[0][item.id];
          }
        });

        this.dialog = true;
      } else {
        this.$refs.cliente.validate().then((res) => {
          if (res) {
            let body = {
              route: "/update_cliente",
              data: {
                data: this.$store.getters.validation_form(this.form.list),
                id: this.id,
              },
            };
            body.data.data.telefono =
              body.data.data.telefono == ""
                ? "0000000"
                : body.data.data.telefono;
            this.$store
              .dispatch("axios_post", body)
              .then((response) => {
                if (response.data.success) {
                  let alert = {
                    alert: true,
                    color: "success",
                    text: response.data.data,
                  };
                  this.$store.dispatch("setAlert", alert);
                  this.loadClientes();
                  this.cancel();
                }
              })
              .catch((error) => {
                this.loading = false;
                let alert = {
                  alert: true,
                  color: "error",
                  text: error.response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
              });
          }
        });
      }
    },
    deleteCliente(item) {
      const body = {
        route: "/delete_cliente",
        data: {
          id: item.id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadClientes();
          }
        })
        .catch((error) => {
          this.loading = false;
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    cancel() {
      this.loading = false;
      this.dialog = false;
      this.id = null;
      this.edit = false;
      this.setForm();
      this.$refs.cliente.reset();
    },
    setForm() {
      this.form.list.forEach((item) => {
        if (item.id == "puntos" || item.id == "estado") {
          item.show = false;
        }
        if (item.id != undefined) {
          item.model = "";
          if (item.id == "fecha") {
            item.model = moment().format("YYYY-MM-DD");
          }

          if (item.id == "genero") {
            item.model = "f";
          }
        }
      });
    },
    setDialogCsv(value) {
      this.dialog_csv = value;
    },
    moment(data) {
      return moment(data);
    },
  },
  created() {
    this.loadClientes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.paginationServe.total / this.paginationServe.perPage;
      if (!is_decimal(number)) number = parseInt(number) + 1;
      return number;
    },
  },
};
</script>
